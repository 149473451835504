import React, { useCallback, useState, Fragment, useRef, FormEvent } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'

const Tabs = () => {
    const [collapseNav, setCollapseNav] = useState(true)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const cancelButtonRef = useRef(null)
    function showNav() {
        if(collapseNav == true) {
            setCollapseNav(false);
        }else{
            setCollapseNav(true);
        }
    };
    
    function login() {
        setOpen(true);
    }
    async function submitLogin (event: FormEvent) {
        setLoading(true)
        let form = event.target as HTMLFormElement;
        const data = new FormData(form);
        axios({
            url: process.env.REACT_APP_API_BASE_URL + '/api/login_check',
            method: "post",
            headers:{
                "Access-Control-Allow-Origin":"*",
            },
            data: {
                username: data.get("username"),
                password: data.get("password"),
            }
            
        }).then((response) => {
              setLoading(false)
              console.log(response);
              sessionStorage.setItem("token",response.data.token);
           })
           .catch((err) => {
              setLoading(false)
              console.log(err.message);
           });
    }
    function LoginModal() {
        return (
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
        
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <form onSubmit={(e) => submitLogin(e)}>
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="">
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Connexion
                                </Dialog.Title>
                                <hr />
                                <div className="mt-2">
                                    <div className="col-span-full my-2">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Username
                                        </label>
                                        <div className="mt-2">
                                            <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            autoComplete="username"
                                            // value={username}
                                            className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            required
                                            // onChange={(e) => handleFormData("username", (e.target as HTMLInputElement).value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-full my-2">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Password
                                        </label>
                                        <div className="mt-2">
                                            <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            autoComplete="password"
                                            // value={password}
                                            // onInput={(e) => handleFormData("password", (e.target as HTMLInputElement).value)}
                                            className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            required
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="submit"
                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                disabled={loading}
                                // onClick={(e) => {
                                //     submitLogin();
                                // }}
                            >
                                <svg className={"animate-spin -ml-1 mr-3 h-5 w-5 text-white " + (loading?"":"hidden")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Se Connecter
                            </button>
                            {/* <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={() => setOpen(false)}
                                ref={cancelButtonRef}
                            >
                                Fermer
                            </button> */}
                            </div>
                      </form>
                    </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          )
    }
    return (
        <nav>
            <div className="nav-mobile visible xl:hidden">
                <div className="rounded-lg">
                    <img src="https://caisse.noblesse-voyage.com/assets/images/logo_noblesse_voyage.jpeg" className='visible xl:hidden mt-2' alt="" width="100%" style={{ maxHeight:"100px" }} />
                </div>
                <span>
                    <button type='button' className='visible xl:invisible mt-8 xl:mt-0' onClick={showNav}>
                        {
                            collapseNav? <i className='fas fa-bars'></i>:<i className="fas fa-times"></i> 
                        }
                        
                    </button>
                    {/* <span className='bg-green-300 text-sm font-mono text-green-600 ml-3 visible xl:hidden p-1 rounded-lg'>Bienvunue chez {process.env.REACT_APP_NAME}</span> */}
                </span>
                <div className="border-b border-gray-200 dark:border-gray-700 md:flex items-center justify-between relative pb-8">
                    <ul className={"flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 mt-[4rem] md:mt-0 " + (collapseNav?"hidden":"")}>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page">
                                <svg className="w-4 h-4 me-2 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                </svg>Ma barre de lancement
                            </a>
                        </li>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                                </svg>Mes instances
                            </a>
                        </li>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                </svg>Intranet et Actualités
                            </a>
                        </li>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                                <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/>
                            </svg>
                            QuickStats
                            </a>
                        </li>
                        
                    </ul>
                    <div className="flex items-center gap-5 absolute right-2 -top-[3rem] md:static mt-6">
                    <a href="#" onClick={login}>
                        <h3 className="flex items-center gap-1">
                            <svg className="text-[30px] text-gray-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fillRule="evenodd" d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0m-5-2a2 2 0 1 1-4 0a2 2 0 0 1 4 0M8 9a4.998 4.998 0 0 0-4.295 2.437A5.49 5.49 0 0 0 8 13.5a5.49 5.49 0 0 0 4.294-2.063A4.997 4.997 0 0 0 8 9" clipRule="evenodd"></path></svg>
                            {/* <span>Minato</span> */}
                        </h3>
                        </a>
                        <svg className="text-[30px] text-gray-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.525 8.3q-.125 0-.262-.075T18.075 8l-.65-1.4l-1.4-.65q-.15-.05-.225-.187t-.075-.263q0-.125.075-.262t.225-.188l1.4-.65l.65-1.4q.05-.15.188-.225t.262-.075q.125 0 .263.075t.187.225l.65 1.4l1.4.65q.15.05.225.188t.075.262q0 .125-.075.263t-.225.187l-1.4.65l-.65 1.4q-.05.15-.188.225t-.262.075Zm2 7.025q-.125 0-.25-.075t-.2-.2l-.35-.75l-.75-.35q-.075-.05-.275-.45q0-.125.075-.25t.2-.2l.75-.35l.35-.75q.05-.075.45-.275q.125 0 .25.075t.2.2l.35.75l.75.35q.075.05.275.45q0 .125-.075.25t-.2.2l-.75.35l-.35.75q-.05.075-.45.275ZM8.4 22q-.375 0-.65-.25t-.325-.625l-.2-1.475q-.2-.075-.388-.2t-.312-.25l-1.375.6q-.35.175-.713.05t-.562-.475l-1.6-2.8q-.2-.325-.113-.7t.388-.6l1.175-.875v-.8l-1.175-.875q-.3-.225-.387-.6t.112-.7l1.6-2.8q.2-.35.563-.475t.712.05l1.375.6q.125-.125.313-.25t.387-.2l.2-1.475q.05-.375.325-.625T8.4 6h3.25q.375 0 .65.25t.325.625l.2 1.475q.2.075.388.2t.312.25l1.375-.6q.35-.175.712-.05t.563.475l1.6 2.8q.2.325.113.7t-.388.6l-1.175.875v.8l1.175.875q.3.225.388.6t-.113.7l-1.6 2.8q-.2.35-.563.475t-.712-.05l-1.375-.6q-.125.125-.313.25t-.387.2l-.2 1.475q-.05.375-.325.625t-.65.25H8.4Zm1.625-5q1.25 0 2.125-.875T13.025 14q0-1.25-.875-2.125T10.025 11q-1.25 0-2.125.875T7.025 14q0 1.25.875 2.125t2.125.875Zm0-2q-.425 0-.713-.288T9.025 14q0-.425.288-.713t.712-.287q.425 0 .713.288t.287.712q0 .425-.288.713t-.712.287Zm-.75 5h1.5l.2-1.8q.725-.2 1.238-.513t1.012-.837l1.65.75l.7-1.25l-1.45-1.1q.2-.575.2-1.25t-.2-1.25l1.45-1.1l-.7-1.25l-1.65.75q-.5-.525-1.012-.837T10.975 9.8l-.2-1.8h-1.5l-.2 1.8q-.725.2-1.237.513t-1.013.837l-1.65-.75l-.7 1.25l1.45 1.1q-.2.575-.213 1.25t.213 1.25l-1.45 1.1l.7 1.25l1.65-.75q.5.525 1.012.838t1.238.512l.2 1.8Zm.75-6Z"></path></svg>
                        {/* <svg className="text-[30px] text-gray-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" fillRule="evenodd" d="M21.95 11h-9.536l3.293-3.293l-1.414-1.414l-5 5l-.707.707l.707.707l5 5l1.414-1.414L12.414 13h9.537c-.502 5.053-4.766 9-9.951 9c-5.523 0-10-4.477-10-10S6.477 2 12 2c5.185 0 9.449 3.947 9.95 9" clipRule="evenodd"></path></svg> */}

                    </div>
                </div>
            </div>
            <div className="nav-desktop hidden xl:block">
                <div className="grid grid-cols-4 gap-4 mb-3">
                    <div className="col-span-3 rounded-lg">
                        {/* <img src="https://caisse.noblesse-voyage.com/assets/images/logo_noblesse_voyage.jpeg" className='mt-2' alt="" style={{ maxHeight:"80px", maxWidth:"120px" }} /> */}
                        <img src="https://p2mpharma.com/wp-content/uploads/2018/01/log-p2m-pharm.png" className='mt-2' alt="" style={{ maxHeight:"80px", maxWidth:"120px" }} />
                    </div>
                    <div className="flex justify-end">
                        <div className={"flex items-center gap-5 absolute right-2 -top-[3rem] md:static mt-8 xl:mt-0" }>
                            <a href="#" onClick={login}>
                                <h3 className="flex items-center gap-1">
                                    <svg className="text-[30px] text-gray-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fillRule="evenodd" d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0m-5-2a2 2 0 1 1-4 0a2 2 0 0 1 4 0M8 9a4.998 4.998 0 0 0-4.295 2.437A5.49 5.49 0 0 0 8 13.5a5.49 5.49 0 0 0 4.294-2.063A4.997 4.997 0 0 0 8 9" clipRule="evenodd"></path></svg>
                                </h3>
                            </a>
                            <svg className="text-[30px] text-gray-700" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M18.525 8.3q-.125 0-.262-.075T18.075 8l-.65-1.4l-1.4-.65q-.15-.05-.225-.187t-.075-.263q0-.125.075-.262t.225-.188l1.4-.65l.65-1.4q.05-.15.188-.225t.262-.075q.125 0 .263.075t.187.225l.65 1.4l1.4.65q.15.05.225.188t.075.262q0 .125-.075.263t-.225.187l-1.4.65l-.65 1.4q-.05.15-.188.225t-.262.075Zm2 7.025q-.125 0-.25-.075t-.2-.2l-.35-.75l-.75-.35q-.075-.05-.275-.45q0-.125.075-.25t.2-.2l.75-.35l.35-.75q.05-.075.45-.275q.125 0 .25.075t.2.2l.35.75l.75.35q.075.05.275.45q0 .125-.075.25t-.2.2l-.75.35l-.35.75q-.05.075-.45.275ZM8.4 22q-.375 0-.65-.25t-.325-.625l-.2-1.475q-.2-.075-.388-.2t-.312-.25l-1.375.6q-.35.175-.713.05t-.562-.475l-1.6-2.8q-.2-.325-.113-.7t.388-.6l1.175-.875v-.8l-1.175-.875q-.3-.225-.387-.6t.112-.7l1.6-2.8q.2-.35.563-.475t.712.05l1.375.6q.125-.125.313-.25t.387-.2l.2-1.475q.05-.375.325-.625T8.4 6h3.25q.375 0 .65.25t.325.625l.2 1.475q.2.075.388.2t.312.25l1.375-.6q.35-.175.712-.05t.563.475l1.6 2.8q.2.325.113.7t-.388.6l-1.175.875v.8l1.175.875q.3.225.388.6t-.113.7l-1.6 2.8q-.2.35-.563.475t-.712-.05l-1.375-.6q-.125.125-.313.25t-.387.2l-.2 1.475q-.05.375-.325.625t-.65.25H8.4Zm1.625-5q1.25 0 2.125-.875T13.025 14q0-1.25-.875-2.125T10.025 11q-1.25 0-2.125.875T7.025 14q0 1.25.875 2.125t2.125.875Zm0-2q-.425 0-.713-.288T9.025 14q0-.425.288-.713t.712-.287q.425 0 .713.288t.287.712q0 .425-.288.713t-.712.287Zm-.75 5h1.5l.2-1.8q.725-.2 1.238-.513t1.012-.837l1.65.75l.7-1.25l-1.45-1.1q.2-.575.2-1.25t-.2-1.25l1.45-1.1l-.7-1.25l-1.65.75q-.5-.525-1.012-.837T10.975 9.8l-.2-1.8h-1.5l-.2 1.8q-.725.2-1.237.513t-1.013.837l-1.65-.75l-.7 1.25l1.45 1.1q-.2.575-.213 1.25t.213 1.25l-1.45 1.1l.7 1.25l1.65-.75q.5.525 1.012.838t1.238.512l.2 1.8Zm.75-6Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="border-b border-gray-200 dark:border-gray-700 md:flex items-center justify-between relative pb-8 xl:pb-0">
                    <ul className={"flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 mt-[4rem] md:mt-0"}>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page">
                                <svg className="w-4 h-4 me-2 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                </svg>Ma barre de lancement
                            </a>
                        </li>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                                </svg>Mes instances
                            </a>
                        </li>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                                <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                                </svg>Intranet et Actualités
                            </a>
                        </li>
                        <li className="me-2">
                            <a href="#" className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                                <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/>
                            </svg>
                            QuickStats
                            </a>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <LoginModal />
        </nav>
    )
}

export default Tabs