import Home from './components/home/Home'

const App = () => {
  return (
    <div className="md:container md:mx-auto max-w-8xl">
    <Home/>
   </div>
  )
}

export default App